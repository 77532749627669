import styled from 'styled-components';
import { device } from '../../../theme';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

export const SectionWrap = styled.section`
  padding: 50px 0;
    padding-bottom: 0;

  @media ${device.small} {
    padding: 10vw 0;
    padding-bottom: 0;
  }
`;

export const TitleContainer = styled.header`
  display: inline-block;
  width: 100%;
  padding: 30px 0;
`;

export const ContentContainer = styled.main`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: 0;
`;

export const CtaButton = styled(Link)`
  margin: 0 auto;
  margin-top: 50px;
  position: relative;
  display: inline-block;
  padding: 8px 38px;
  background: #111;
  color: #ececec;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-size: 0.9em;
  font-weight: 500;
  line-height: 1.8em;
  border-radius: 0;
  transition: all 0.3s;
  border: 1px solid rgba(17, 17, 17, 0);

  &:hover {
    border: 1px solid rgba(17, 17, 17, 1);
    background: #fff;
    color: #111;
  }
`;

export const DetailWrap = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
  background-color: #f8f8f8;
  &:nth-child(odd) {
    flex-direction: row-reverse;
    background-color: #fff;
  }
`;

export const DetailContent = styled.div`
  width: 50%;
  padding: 85px 65px;
  padding-bottom: 100px;

  h4 {
    display: block;
    font-size: 1.2em;
    letter-spacing: 3px;
    color: #111;
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: 10px;
    margin: 0;
  }

  h5 {
    display: block;
    font-size: 1em;
    letter-spacing: 2px;
    color: #999;
    text-transform: uppercase;
    padding-bottom: 35px;
  }

  p {
    font-size: 0.9em;
    font-weight: 300;
    letter-spacing: 0.2px;
    line-height: 1.8;
    color: #444;
  }

  a.button {
    margin-top: 50px;
    position: relative;
    display: inline-block;
    padding: 8px 38px;
    background: #111;
    color: #ececec;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 1.8em;
    border-radius: 0;
    transition: all 0.3s;
    border: 1px solid rgba(17, 17, 17, 0);

    &:hover {
      border: 1px solid rgba(17, 17, 17, 1);
      background: #fff;
      color: #111;
    }

    @media ${device.small} {
      margin-top: 20px;
    }
  }

  @media ${device.small} {
    width: 90%;
    padding: 85px 10%;
    text-align: justify;
    h4, h5 {
      text-align: left;
    }
  }
`;

export const DetailMedia = styled(Img)`
  width: 5%;
  flex-grow: 1;
  height: auto;
  z-index: 2 !important;
`;