import styled from 'styled-components';
import { device } from '../../theme';
import { Link } from 'gatsby';

export const CtaWrapper = styled.div`
  background: url(${(props) => props.ctaBg}) repeat;
`;

export const Overlay = styled.div`
  width: 100%;
  background: rgba(17, 17, 17, 0.5);
  @media ${device.medium} {
    padding: 10vh 10%;
  }
  @media ${device.small} {
    padding: 10vh 10%;
  }
`;

export const Content = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;

  h3 {
    font-weight: 300;
    font-size: 2.2em;
    margin-top: 0;
    margin-bottom: 50px;
    letter-spacing: 8px;
    color: #ececec;
    text-transform: uppercase;
  }



  @media ${device.large} {
    padding: 0 6.25vw;
  }

  @media ${device.small} {
    h2 {
      font-size: 1em;
    }
  }
`;

export const CtaButton = styled(Link)`
  margin: 0 auto;
  position: relative;
  display: inline-block;
  padding: 8px 38px;
  background: #ececec;
  color: #111;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-size: 0.9em;
  font-weight: 500;
  line-height: 1.8em;
  border-radius: 0;
  transition: all 0.3s;
  border: 1px solid rgba(238, 238, 238, 0);

  &:hover {
    border: 1px solid rgba(238, 238, 238, 1);
    background: #11111144;
    color: #ececec;
  }

  &:visited {
    color: #111;
    &:hover {
      color: #ececec;
    }
  }
`;