import React from 'react';
import { HeroContainer, Content } from './hero.stc';

const Hero = ({ title, subhead, image, alt }) => {
  return (
    <div className="hero">
      {image && (
        <HeroContainer fluid={image} alt={alt}>
          <div className="overlay"></div>
          <Content>
            {title && <h1>{title}</h1>}
            {subhead && <h2>{subhead}</h2>}
          </Content>
        </HeroContainer>
      )}
    </div>
  );
};

export default Hero;
