import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../containers/layout/layout';
import Project from '../../components/project';
import Hero from '../../components/hero';
import Body from '../../containers/single-project/body';
import DetailList from '../../containers/single-project/detail/list';
import Cta from '../../components/cta'
import clientConfig from '../../../client-config';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';

import {
  ProjectDetailsWrap,
  NextProjectWrap,
} from './project-template.stc';

const ProjectTemplate = ({
  data,
  pageContext: { next, previous },
  ...restProps
}) => {
  const { nextProjectStyle } = restProps;
  const projectData = data.sanityProject;

  const projectDetails = projectData._rawDetails;

  const featureImage = getFluidGatsbyImage(
    projectData._rawImg.asset._ref,
    {},
    clientConfig.sanity
  );

  const seoMeta = projectData.seo;
  const openGraph = projectData.openGraph;

  return (
    <Layout headerLayout={2}>
      <SEO
        title={seoMeta?.seo_title}
        ogtitle={openGraph?.title}
        description={seoMeta?.meta_description}
        ogdescription={openGraph?.description}
        image={openGraph?.image?.asset?.fluid}
      />
      <Hero
        title={projectData.title}
        subhead={`For ${projectData.client}`}
        image={featureImage}
        alt={''}
      />
      <Body
        title={projectData.title}
        subhead={projectData.category}
        copy={projectData._rawCopy}
        image={featureImage}
        titleAlign="center"
        client={projectData.client}
        category={projectData.category}
        year={projectData.year}
      />

      <ProjectDetailsWrap>
        {projectDetails && <DetailList details={projectDetails} />}
      </ProjectDetailsWrap>


      <NextProjectWrap>
      <Cta title={'Ready to start a project?'} call={'Let\'s Talk'} action={'/contact'} active={true} />

        <div className="project-container">
          <div className="project-wrapper">
            {next && (
              <Project
                {...nextProjectStyle}
                isEven={true}
                slug={next._rawSlug.current}
                image={next._rawImg}
                title={next.title}
                client={next.client}
                category={next.category}
              />
            )}
            {!next && previous && (
              <Project
                {...nextProjectStyle}
                isEven={true}
                slug={previous._rawSlug.current}
                image={previous._rawImg}
                title={previous.title}
                client={previous.client}
                category={previous.category}
              />
            )}
          </div>
        </div>
      </NextProjectWrap>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    sanityProject(_id: { eq: $id }) {
      id
      slug {
        current
      }
      title
      client
      category
      year
      img {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawCopy
      _rawImg
      _rawDetails

      seo {
        meta_description
        seo_title
      }
      openGraph {
        title
        description
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;

ProjectTemplate.propTypes = {
  title: PropTypes.object,
  metaHeading: PropTypes.object,
  metaText: PropTypes.object,
};

ProjectTemplate.defaultProps = {
  title: {
    color: 'primary',
    fontSize: '40px',
    lineHeight: '55px',
    responsive: {
      medium: {
        fontSize: '24px',
        lineHeight: 'initial',
      },
    },
  },
  metaHeading: {
    as: 'h6',
    color: 'primary',
    fontSize: '12px',
    fontweight: 700,
    letterspacing: '2px',
    mb: '12px',
  },
  metaText: {
    m: 0,
    fontSize: '12px',
    color: '#000000',
    letterspacing: '1px',
  },
  nextProjectStyle: {
    mt: '100px',
    responsive: {
      medium: {
        mt: '60px',
      },
    },
  },
};

export default ProjectTemplate;
