import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import {device} from '../../theme';

export const HeroContainer = styled(BackgroundImage)`
  padding: 140px 0;

  .overlay {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(17,17,17,0.70);
    z-index: 1;
  }
`;

export const Content = styled.div`
  width: 1100px;
  padding: 0 25px;  
  margin: 0 auto;
  text-transform: uppercase;

  h1 {
    font-weight: 600;
    line-height: 1.1;
    font-size: 2.2em;
    letter-spacing: 4px;
    margin: 0;
    color: #ececec;
  }

  h2 {
    margin: 20px 0 0 0;
    font-weight: 400;
    color: #bbb;
    font-size: 1.3em;
    letter-spacing: 3px;
    width: 90vw;
  }

  @media ${device.large} {
    padding: 0 6.25vw;
  }

  @media ${device.small} {
    h2 {
      font-size: 1em;
    }
  }

  @media ${device.xsmall} {
    h1 {
      font-size: 1.5em;
    }
  }
  @media ${device.xxsmall} {
    h1 {
      font-size: 1em;
    }
  }
`;