import React from 'react';
import { CtaWrapper, Overlay, Content, CtaButton } from './cta.stc';
import ctaBg from '../../assets/img/shattered.gif';

const Cta = ({ title, call, action, active }) => {
  if (active) {
    return (
      <CtaWrapper ctaBg={ctaBg}>
        <Overlay>
          <Content>
            <h3>{title}</h3>
            <CtaButton to={`/${action}`}>{call}</CtaButton>
          </Content>
        </Overlay>
      </CtaWrapper>
    );
  } else {
    return <></>;
  }
};

export default Cta;
