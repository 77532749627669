import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { DetailWrap, DetailContent, DetailMedia} from './detail.stc'

const DetailPanel = ({ title, copy, image, alt }) => {
  return (
    <DetailWrap>
      <DetailContent>
        {title && <h4>{title}</h4>}
        {copy && <BlockContent blocks={copy} />}
      </DetailContent>
      <DetailMedia fluid={image} alt={alt} />
    </DetailWrap>
  );
};

export default DetailPanel;