import styled from "styled-components";
import { device } from "../../../theme";
import Img from 'gatsby-image';
import React from 'react';

export const BodySectionWrap = styled.section`
  display: flex;
  padding: 0 12.5%;

  @media ${device.small} {
    padding: 0 8.75%;
    display: inline-block;
  }
`;

export const BodyContentContainer = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 25px 12.5%;
  @media ${device.medium} {
    padding: 25px 0;
  }
  
`;

export const BodyTitleContainer = styled.header`
  width: 100%;
  padding: 10px 0;
  padding-top: 50px;
  @media ${device.small} {
    text-align: center;
    padding-bottom: 0;
  }
`;

export const BodyCopy = styled.article`
  padding: 50px 0;
  text-align: justify;
  @media ${device.medium} {
    padding: 25px 5%;
    width: 100%;
  }
`;

export const Accent = styled(Img)`
  @media ${device.medium} {
    margin: 25px 0;
  }
`;

export const DetailsWrap = styled.div`
text-align: center;
  h5 {
    display: inline-block;
    font-size: .8em;
    font-weight: 400;
    color: #444;
    padding: 25px 0;
    padding-right: 50px;

    span {
      text-transform: uppercase;
      color: #777;
      letter-spacing: 1.5px;
      padding-right: 5px;
    }
  }
  @media ${device.small} {
    padding: 25px 0 10px 0;
    h5 {
      display: block;
      padding: 5px 0;
      margin: 0;
    }
  }
`;

export const InfoItem = ({ title, content }) => {
  return (
    <h5><span>{title}</span> {content}</h5>
  )
}