import React from 'react';
import DetailPanel from './panel';
import { ContentContainer } from './detail.stc';
import clientConfig from '../../../../client-config';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';

const DetailList = ({ details }) => {
  return (
    <ContentContainer>
      {details.map((detail, i) => {
        const detailImage = getFluidGatsbyImage(detail.img, {}, clientConfig.sanity);
        return (
          <DetailPanel
            key={detail._key}
            i={i}
            title={detail.title}
            copy={detail.copy}
            image={detailImage}
            alt={detail.img.alt}
          />
        );
      })}
    </ContentContainer>
  );
};

export default DetailList;
